* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Oswald', sans-serif;
}
:root {
  --maincolor: #465676;
  --darkermain: #273e6b;
  --secondarycolor: #0261a0;
  --thirdcolor: #ff9d00;
  --darkwhite: #9c1010;
}
.consent {
  color: var(--maincolor);
  text-decoration: none;
}
strong {
  color: var(--thirdcolor);
}
.container {
  height: 200vh;
}

li {
  font-weight: 200;
}
.dropdown-menu {
  position: absolute;
  top: 80px;
  text-align: start;
  z-index: 10;
  flex-direction: column;
  background: linear-gradient(180deg, #000 -60%, var(--secondarycolor));
  box-shadow: 0 7px 10px #000;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 200px;
}

.dropdown-menu li {
  width: 100%;
  height: 80px;
}

.dropdown-item {
  color: #fff;
}
.dropdown-item:hover {
  background: #16243f;
}
.dropdown-menu .hovered {
  display: none;
}

#bc hr,
#management hr,
#hr hr,
#train hr,
#recruit hr,
#career hr,
#apply hr,
#tm hr,
#about hr {
  border: dashed 2px #fff;
  width: 100%;
  margin-bottom: 30px;
}
#train hr,
#contact hr,
#history hr,
#mission hr,
#legal hr,
#marlins hr {
  border: dashed 2px #16243f;
  width: 100%;
  margin-bottom: 30px;
}
#tm h3,
#tm h2 {
  width: 100%;
  color: #fff;
  text-align: justify;
}

#tm h2 {
  font-size: 40px;
}
@media screen and (max-width: 575.98px) {
  #tm h3,
  #tm h2 {
    width: 100%;
    color: #fff;
    text-align: center;
  }

  #tm h2 {
    font-size: 25px;
  }
  #tm h3 {
    font-size: 18px;
  }
}
.download {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.download a {
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.download a .iconDownload {
  margin-left: 20px;
}

#tc,
#gdpr,
#cookie {
  display: flex;
  align-items: center;
  color: #fff;
  height: 50px;
}
#tc input,
#gdpr input,
#cookie input {
  margin-right: 20px;
}

#train ul {
  height: 100px;
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
#hr ul,
#management ul {
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  list-style: none;
}
#train ul li,
#hr ul li,
#management ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  font-size: 20px;
}
#train ul li {
  line-height: 2rem;
}
#train ul li svg,
#hr ul li svg,
#management ul li svg {
  font-size: 30px;
  margin-right: 10px;
}

input[type='file'] {
  display: none;
}

.uploadButton {
  border: 3px solid transparent;
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #0261a0;
  transition: all 0.8s ease-out;
}

.uploadButton:hover {
  background-color: transparent;
  border: 3px dashed #ccc;
}
#contact h2 {
  font-size: 26px;
}
#contact ul {
  height: 200px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  list-style: none;
  text-align: center;
  font-size: 20px;
}
#contact li a {
  color: #16243f;
  text-decoration: none;
}

#apply li {
  list-style: decimal;
}
@media screen and (max-width: 575.98px) {
  #hr ul,
  #management ul {
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }
  label {
    font-size: 15px;
  }

  #management ul li {
    width: 100%;
  }
}

/* Divider  Blue*/
.custom-shape-divider-bottom-1644266914 {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1644266914 svg {
  position: relative;
  display: block;
  width: calc(116% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1644266914 .shape-fill {
  fill: #16243f;
}

/* Divider Blue Top */

.custom-shape-divider-top-1644266819 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1644266819 svg {
  position: relative;
  display: block;
  width: calc(116% + 1.3px);
  height: 150px;
}

.custom-shape-divider-top-1644266819 .shape-fill {
  fill: #16243f;
}

/* Divider White */
.custom-shape-divider-bottom-1644266428 {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  background-color: #16243f;
}

.custom-shape-divider-bottom-1644266428 svg {
  position: relative;
  display: block;
  width: calc(118% + 1.3px);
  height: 118px;
}

.custom-shape-divider-bottom-1644266428 .shape-fill {
  fill: #ffffff;
}

/* Divider White Top */
.custom-shape-divider-top-1644268125 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  background-color: #16243f;
}

.custom-shape-divider-top-1644268125 svg {
  position: relative;
  display: block;
  width: calc(118% + 1.3px);
  height: 118px;
}

.custom-shape-divider-top-1644268125 .shape-fill {
  fill: #ffffff;
}
#cookiep i,
#gdprp i,
#tcs i,
#tou i {
  color: #fff;
  font-size: 20px;
  margin-bottom: 20px;
}
